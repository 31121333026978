import { mergeSelectorConfiguration } from 'helper/mergeHelper';
import config, { SelectorConfiguration } from 'selectorConfiguration';
import baseConfiguration from '../../../../legacy/customer/base/losteria/baseSelectorConfiguration';

let brandadSelectors: SelectorConfiguration = {
	startup: [],
	'#infoPageControls': {
		1: {
			name: 'InfoPage',
			requirePath: 'plugins/jquery.infoPage',
		},
	},
	'.truncatable': {
		1: {
			name: 'SelectTextTruncator',
			requirePath: 'plugins/textTruncator/jquery.selectTextTruncator',
		},
	},
	'input[type="checkbox"].lockcheckbox, input[type="checkbox"].favoriteMark': {
		1: {
			name: 'CustomFormElements',
			requirePath: 'plugins/jquery.customFormElements',
		},
	},
	'.interactionPortlet .superviewButton, .resultSet.detail .templatePreview.superviewButton': {
		1: {
			name: 'Lightbox',
			requirePath: 'plugins/lightbox/jquery.lightbox',
			initializationContext: '.portlet',
			configurationName: 'Lightbox',
		},
	},
	'img#superviewimg': {
		1: {
			name: 'Lightbox',
			requirePath: 'plugins/lightbox/jquery.lightbox',
			initializationContext: '.resultSet .resultSetItem',
			configurationName: 'LightboxSuperview',
		},
	},
	'.resultSet.superview': {
		1: {
			name: 'SuperviewPreviewSwitch',
			requirePath: 'plugins/jquery.superviewPreviewSwitch',
		},
	},
	'#pictureDbPreviewButton': {
		1: {
			name: 'LightboxBildDB',
			requirePath: 'plugins/lightbox/jquery.lightboxBildDB',
			initializationContext: 'div.picturePreview.hasLightbox',
			configurationName: 'BildDBLightbox',
		},
	},
	'#editMask': {
		1: {
			name: 'TextareaLinebreak',
			requirePath: 'plugins/jquery.textarea-linebreak',
		},
	},
	'.ui-lab.color-dummy': {
		1: {
			name: 'ColorDisplay',
			requirePath: 'plugins/uilab/jquery.colorDisplay',
		},
	},
	'.sourceDestinationSet': {
		1: {
			name: 'SourceDestinationSet',
			requirePath: 'plugins/jquery.sourceDestinationSet',
		},
	},
	'.umfrageTeilnahme': {
		1: {
			name: 'UmfrageTeilnahme',
			requirePath: 'plugins/jquery.umfrageTeilnahme',
		},
	},
};

let mergedConfig = mergeSelectorConfiguration(config, brandadSelectors);
export default mergeSelectorConfiguration(mergedConfig, baseConfiguration);
